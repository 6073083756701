import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Page not found (404)" />
    <h1>ERR_NO:404 | CANNOT COMPUTE 🤖</h1>
    <p>You just hit a page that doesn't exist. Try again.</p>
  </Layout>
)

export default NotFoundPage
